import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ResultsPage from "../components/ResultsPage";
import { ResultsQuery } from "../graphql";

const ResultsPageHOC: React.FC<{}> = () => {
  const query = useStaticQuery<ResultsQuery>(graphql`
    query {
      intro: file(
        sourceInstanceName: { eq: "snippets" }
        name: { eq: "results" }
      ) {
        id
        childMdx {
          frontmatter {
            title
          }
          body
        }
      }
      results: allFile(
        sort: { fields: childMdx___frontmatter___date, order: DESC }
        filter: { sourceInstanceName: { in: ["results"] } }
      ) {
        nodes {
          id
          name
          childMdx {
            frontmatter {
              title
              date
              distances {
                type
                distance
              }
              place
              time
              url
              image
            }
            body
          }
        }
      }
    }
  `);
  return <ResultsPage query={query} />;
};

export default ResultsPageHOC;
