import React, {
  useState,
  useCallback,
  MouseEventHandler,
  useEffect,
} from "react";
import Layout from "./Layout";
import Head from "./Head";
import Monogram from "./Monogram";
import Header from "./Header";
import {
  RaceDistance,
  RaceResultItem,
  RaceType,
  RaceTypes,
  ResultsQuery,
} from "../graphql";
import MarkdownContent from "./MarkdownContent";
import RaceResultDistance, { RaceResultPlace } from "./RaceResultDistance";
import { Link } from "gatsby";
import { tabularDate } from "../util/dates";
import * as classNames from "./ResultsPage.module.css";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { H3 } from "./MarkdownElements";

const RaceCategory = {
  SWIMRUN: "Swimrun",
  HALF_IRON: "Half Triathlon (70.3)",
  OLYMPIC: "Olympic Triathlon",
  TRI: "Triathlon",
  RUN: "Run",
  CYCLING: "Cycling",
  OPEN_WATER_SWIM: "Open Water Swim",
  OTHER: "Other Races",
} as const;
type RaceCategories = typeof RaceCategory[keyof typeof RaceCategory];

const singleTypeMap = new Map<RaceTypes, RaceCategories>([
  [RaceType.Bike, RaceCategory.CYCLING],
  [RaceType.Run, RaceCategory.RUN],
  [RaceType.Swim, RaceCategory.OPEN_WATER_SWIM],
]);

function isSwimRun(types: Set<RaceTypes>): boolean {
  return (
    types.size === 3 &&
    types.has(RaceType.Swim) &&
    types.has(RaceType.Run) &&
    types.has(RaceType.Segments)
  );
}

function isTri(types: Set<RaceTypes>): boolean {
  return (
    types.size === 3 &&
    types.has(RaceType.Swim) &&
    types.has(RaceType.Bike) &&
    types.has(RaceType.Run)
  );
}

function isTimeOnlyCategory(category: RaceCategories): boolean {
  switch (category) {
    case RaceCategory.HALF_IRON:
    case RaceCategory.OLYMPIC:
      return true;
    default:
      return false;
  }
}

export function getCategory(distances: RaceDistance[] = []): RaceCategories {
  const types = new Set(distances.map(({ type }) => type));

  // If we have a segments type, that's a swimrun
  if (isSwimRun(types)) {
    return RaceCategory.SWIMRUN;
  }

  // If there's only one type, return that type's category
  if (types.size === 1) {
    return singleTypeMap.get(Array.from(types)[0]) || RaceCategory.OTHER;
  }

  // If it's a tri
  if (isTri(types)) {
    // Return based on bike distance
    switch (distances[1].distance) {
      case "56mi":
        return RaceCategory.HALF_IRON;
      case "40km":
        return RaceCategory.OLYMPIC;
      default:
        return RaceCategory.TRI;
    }
  }

  // Return generic tri
  return RaceCategory.OTHER;
}

const ResultPageLink: React.FC<{ result: RaceResultItem }> = ({ result }) => (
  <Link to={`/results/${result.name}`}>
    {result.childMdx.frontmatter.title}
  </Link>
);

const ResultPageDate: React.FC<{ result: RaceResultItem }> = ({ result }) => (
  <>{tabularDate(`${result.childMdx.frontmatter.date}`)}</>
);

const ResultsTimeOnly: React.FC<{ results: RaceResultItem[] }> = ({
  results,
}) => (
  <table className={classNames.resultsTimeOnly}>
    <thead>
      <tr>
        <th>Race</th>
        <th>Date</th>
        <th>Results</th>
      </tr>
    </thead>
    <tbody>
      {results.map((result) => {
        const { place, time } = result.childMdx.frontmatter;
        return (
          <tr>
            <th>
              <ResultPageLink result={result} />
            </th>
            <td>
              <ResultPageDate result={result} />
            </td>
            <td>
              {time}&nbsp;
              <RaceResultPlace place={place} />
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

const ResultsChrono: React.FC<{ results: RaceResultItem[] }> = ({
  results,
}) => (
  <table className={classNames.resultsChrono}>
    <thead>
      <tr>
        <th>Race</th>
        <th>Results</th>
      </tr>
    </thead>
    <tbody>
      {results.map((result, j) => {
        const { distances, place, time } = result.childMdx.frontmatter;
        return (
          <tr key={j}>
            <th>
              <ResultPageLink result={result} />
              <br />
              <span className={classNames.chronoDate}>
                <ResultPageDate result={result} />
              </span>
            </th>
            <td>
              <RaceResultDistance
                distances={distances}
                place={place}
                time={time}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

const ResultsByType: React.FC<{ results: RaceResultItem[] }> = ({
  results,
}) => {
  const racesByType = results.reduce<Map<RaceCategories, RaceResultItem[]>>(
    (init, result) => {
      const category = getCategory(result.childMdx.frontmatter.distances);
      const raceList = init.get(category) || [];
      raceList.push(result);
      init.set(category, raceList);
      return init;
    },
    new Map()
  );
  return (
    <>
      {Object.values(RaceCategory).map((category) => {
        const raceList = racesByType.get(category);
        return raceList ? (
          <>
            <H3>{category}</H3>
            {isTimeOnlyCategory(category) ? (
              <ResultsTimeOnly results={raceList} />
            ) : (
              <ResultsChrono results={raceList} />
            )}
          </>
        ) : null;
      })}
    </>
  );
};

const RenderState = {
  LOADING: "none",
  CHRONO: "chrono",
  BY_TYPE: "by-type",
} as const;

type RenderStates = typeof RenderState[keyof typeof RenderState];

const STATE_QUERY = "view";

function isRenderState(value: any): value is RenderStates {
  return value === RenderState.BY_TYPE || value === RenderState.CHRONO;
}

function getTarget(state: RenderStates): {
  newState: RenderStates;
  url: string;
} {
  const newState =
    state === RenderState.CHRONO ? RenderState.BY_TYPE : RenderState.CHRONO;
  return {
    newState,
    url: `?${new URLSearchParams({ [STATE_QUERY]: newState })}`,
  };
}

const ResultsToggle: React.FC<{
  renderState: RenderStates;
  setRenderState: (state: RenderStates) => void;
}> = ({ renderState, setRenderState }) => {
  const { newState, url } = getTarget(renderState);
  const toggle = useCallback<MouseEventHandler>(
    (e) => {
      e.preventDefault();
      history.replaceState(history.state, "", url);
      setRenderState(newState);
    },
    [newState, url, setRenderState]
  );
  return renderState === RenderState.LOADING ? null : renderState ===
    RenderState.CHRONO ? (
    <p>
      <b>Chronological</b> |{" "}
      <a href={url} onClick={toggle}>
        By Type
      </a>
    </p>
  ) : (
    <p>
      <a href={url} onClick={toggle}>
        Chronological
      </a>{" "}
      | <b>By Type</b>
    </p>
  );
};

/**
 * Functional component representing the results page
 *
 * Gatsby assumes we're renering in an SSR context, so there's extra complexity
 * here to move URL introspection into a useEffect hook so that it doesn't try
 * to run server-side.
 */
const ResultsPage: React.FC<{ query: ResultsQuery }> = ({ query }) => {
  const [renderState, setRenderState] = useState<RenderStates>(
    RenderState.LOADING
  );
  useEffect(() => {
    const viewState = new URL(window.location.href).searchParams.get(
      STATE_QUERY
    );
    setRenderState(isRenderState(viewState) ? viewState : RenderState.CHRONO);
  }, [setRenderState]);
  const { results, intro } = query;
  const {
    body: introBody,
    frontmatter: { title: pageTitle },
  } = intro.childMdx;

  return (
    <Layout>
      <Head title={pageTitle} />
      <Header title={pageTitle} />
      <MarkdownContent brief={true}>
        <MDXRenderer>{introBody}</MDXRenderer>
        <ResultsToggle
          renderState={renderState}
          setRenderState={setRenderState}
        />
        {renderState === RenderState.CHRONO ? (
          <ResultsChrono results={results.nodes} />
        ) : renderState === RenderState.BY_TYPE ? (
          <ResultsByType results={results.nodes} />
        ) : null}
      </MarkdownContent>
      <Monogram footer={true} />
    </Layout>
  );
};

export default ResultsPage;
